import { IconsVariants } from "@/ui/Icons/Icon";

type Route = {
  path: string;
  name: string;
  icon: IconsVariants;
};

export const routes: Route[] = [
  { path: "/", name: "Main", icon: "home" },
  { path: "/users", name: "Users", icon: "people" },
  { path: "/positions", name: "Positions", icon: "emptyStar" },
  { path: "/skills", name: "Skills", icon: "favorite" },
  { path: "/ml_settings", name: "ML Settings", icon: "settings" },
];
