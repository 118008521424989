import { createBrowserRouter } from "react-router-dom";
import { App } from "@/components/App/App";
import React, { Suspense } from "react";
import { LazyLogin } from "@/pages/login/Login.lazy";
import { LazyUsers } from "@/pages/users/Users.lazy";
import { LazySkills } from "@/pages/skills/Skills.lazy";
import { LazyPositions } from "@/pages/positions/Positions.lazy";
import { LazyUser } from "@/pages/users/User/User.lazy";
import { LazyMLSettings } from "@/pages/MLSettings/MLSettings.lazy";
import { LazyResumePage } from "@/pages/resumes/ResumePage.lazy";
import { LazyVacancyPage } from "@/pages/vacancies/VacancyPage.lazy";

const routes = [
  {
    element: <App />,
    children: [
      {
        path: "/",
        element: "",
      },
      {
        path: "/login",
        element: (
          <Suspense fallback="Loading...">
            <LazyLogin />
          </Suspense>
        ),
      },
      {
        path: "/users",
        element: (
          <Suspense fallback="Loading...">
            <LazyUsers />
          </Suspense>
        ),
      },
      {
        path: "/users/:id",
        element: (
          <Suspense fallback="Loading...">
            <LazyUser />
          </Suspense>
        ),
      },
      {
        path: "/resume/:id",
        element: (
          <Suspense fallback="Loading...">
            <LazyResumePage />
          </Suspense>
        ),
      },
      {
        path: "/vacancies/:id",
        element: (
          <Suspense fallback="Loading...">
            <LazyVacancyPage />
          </Suspense>
        ),
      },
      {
        path: "/skills",
        element: (
          <Suspense fallback="Loading...">
            <LazySkills />
          </Suspense>
        ),
      },
      {
        path: "/positions",
        element: (
          <Suspense fallback="Loading...">
            <LazyPositions />
          </Suspense>
        ),
      },
      {
        path: "/ml_settings",
        element: (
          <Suspense fallback="Loading...">
            <LazyMLSettings />
          </Suspense>
        ),
      },
    ],
  },
];

export const router = createBrowserRouter(routes);
